import * as React from 'react';
import ReactDOM from 'react-dom';
//import logo from './logo.svg';
import './App.css';
//import { useMachine } from '@xstate/react';
//import { ftTele  }    from './ftTeleFSM';
//import { update }     from 'xstate/lib/actionTypes';

var   buttonState     = "down";
var   channelState    = "disconnected";
var connectAttempts   = 0;

const shortTaskBar = window.hasCollapse;

class DSDate extends (Date) {

  dsYearAndDay() {
    const utc   = new Date();
    const year  = utc.getFullYear() - 1950;
    const month = utc.getUTCMonth() + 1;
    const day   = utc.getUTCDate();
    return year + "\n" + month + "-" + day;   

  }

  twoDigitTOD() {
    const utc = new Date();
    const h = utc.getUTCHours(); const m = utc.getUTCMinutes(); const s  = utc.getUTCSeconds();
    var hours   = (h < 10) ? ("0" + h) : h;
    var minutes = (m < 10) ? ("0" + m) : m;
    var seconds = (s < 10) ? ("0" + s) : s;
    
    return ( hours + ":" + minutes + ":" + seconds)  ;
  }
}

class App extends React.Component {
  
  teleConnect() {


   }

  buttonPress() {

    const element = document.getElementById('rudeDoorbell');

    var np = (connectAttempts < 3) ? 'step No. ' : 'try No.';

    switch (buttonState) {
      case 'down': // Try to bring the doorbell service MCS up and move to ATTENTION
        if (channelState !== "connected") {
          setTimeout(function () {
            if (channelState === 'connected') {
              element.style.backgroundImage = "url('teleDBAttn.png')";
              buttonState = "offline";
            } else {
              element.style.backgroundImage = "url('teleDoorBell.png')";
              buttonState = "offline";
            }
          }, 5000);
        }
        alert("it's down: connect " + np + connectAttempts++);
        break;
      case 'offline': if (channelState !== "connected") {
 //       this.teleConnect();
        setTimeout(function () {
          if (channelState === 'connected') {
            element.style.backgroundImage = "url('teleDBAttn.png')";
            buttonState = "connected";
          } else {
            element.style.backgroundImage = "url('teleDoorBell.png')";
            buttonState = "offline";
          }
        }, 5000);
      }
        alert("it's offline: connect " + np + ++connectAttempts);
        break;
      case 'connected': ;
        break;
    }
  }   
     
  render() {
    if (!shortTaskBar)
    return (
      <table class="S1" cell-spacing ="0px"><tr>
      <td width="7%"> &nbsp; <div class="S2">
          <a class="S3" rel="noopener, noreferrer"  target="_blank" title="guacamole, jidesha, or a static image"
           href="https://eg.meansofproduction.biz/eg/index.php/AboutFtDesktop">DESKTOP</a><br/>
            <a class="S3"  rel="noopener noreferrer" target="_blank"
	       title="DS Jitsi or other backend VT provider" href="https://jitsi.sameboat.network">&laquo; Conf &raquo;</a><br/>
          <a class="S3" target="_top" title="Change the Ft wait clip genre (jazz or classical) in your commons profile" href="https://sameboat.network/user">GENRE</a>
	    </div></td>
      <td width="1%" valign="center" align="top"></td>
      <td width="57%" valign="top"><table cellPadding="0" ><tr>
      <td align="left" valign="top"><b>	   
            <span class="S14" > &nbsp; BUSY    &nbsp; </span> ,&nbsp;
            <span class="S13" > &nbsp; CONF    &nbsp; </span> , &nbsp;
            <span class="S10" > &nbsp; OFFHOOK &nbsp; </span> , &nbsp;
            <span class="S16" > &nbsp; OFFLINE &nbsp; </span> , &nbsp;
            <span class="S11" > &nbsp; PAGE    &nbsp; </span> , &nbsp;
            <span class="S15" > &nbsp; RING    &nbsp; </span> , &nbsp;
            <span class="S12" > &nbsp; WAIT    &nbsp; </span>  </b> 
      </td></tr>
      <tr>
      <td><form id="Tick" class="S4">
       <input type="text" class="S7" name="logLine" value={new Date().toLocaleTimeString() + "  " + window.clientIP + " Ft client load event."}></input>
      <div class="S8" id="localClock"></div></form></td>
      </tr></table></td>
       <td class="L1" valign="top" align="center" width="10%">
	      <a title="Click for more info on call model" target="blank" href="https://eg.meansofproduction.biz/eg/index.php/Ft_Protocol">about doorbell</a><br></br>
        <form action="/" id="f1-form"><input type="hidden" name="TCOLL" id="TCOLL" value="1"></input>
         <input name="F1" id="F1" type="text" title="Enter an F1 code such as '002001'" class="L2"></input> <br></br>
         <input type="submit" value="&uarr; FI Code &uarr;" class="BF1"></input></form>
       </td>
       <td valign="top" align="center" width="10%"><span class="SX1">
         <a title="DS AutoConsult/2"  rel="noopener, noreferrer"  target="_blank"
          href="https://ac.ai-integration.biz"><font size="1"><b>AI</b></font></a> <br></br>
            <font size="1"><b><a title="Domain Space App Platform"
             target="_blank"  href="https://devops1.sameboat.network/sb-app">DSAP</a></b></font></span></td>
      <td width="1%" valign="middle" align="center"> 
           <a href="/?TCOLL=1">
             <img alt="" class="P1F" title="collapse Ft legendbar" src="flapBack.png"/></a>
    </td>
       <td align="right" width="15%"> <span class="db">
         <a title='Ft Ringer, see legend for button color significance' href="https://sameboat.network/Tele">
           <img class="dbc" alt="" width='150' src="https://meansofproduction.biz/images/teleDoorBell.png"/></a>
            </span></td>
      </tr>
   </table>) 
      else if (window.self === window.top)
    return (
      <table class="S1C" cell-spacing="0px" border="0"><tr>
      <td width="67px" align="center" valign='absmiddle'> &nbsp; <div class="S2">
          <a class="S3" target="_blank" rel="noopener, noreferrer" title="The Ft Tele Story"
           target="_blank"  href="https://eg.meansofproduction.biz/eg/index.php/Tele"> About</a> </div>
        
        <div class="S8C" id="localClock2"></div>
        </td> 
        <td> &nbsp; &nbsp; </td>
      <td align="center" > 
          <button class="dbc" id="rudeDoorbell" onClick={() => {this.buttonPress()}}></button>
          <div class="S9C"> <a title="Zoom, Ft Jitsi depending on profile settings, defaults to Ft Jitsi"
            target="_blank" href="https://jitsi.sameboat.network">VT Provider</a></div>
        </td>
      <td width="55px" valign="center" align="left">
       <a href="/?TCOLL=0"><img alt=""  title="expand Ft legendbar" src="flap.png"/></a><br></br></td>
      <td ><center><div class="S9C1"> <a title="Domain Space App Platform" target="_blank"
                 href="https://devops1.sameboat.network/sb-app">DSAP</a><br></br>
            <a href="https://eg.meansofproduction.biz/eg/index.php/AboutFtDesktop#Desktop_Sharing_Major_Function"
              title="Guacamole Web Screen Sharing" ><br></br>FtRDP<br></br>
             </a></div></center></td>  
      </tr>
   </table>) ; else 

  return (
      <table class="S1C" cell-spacing="0px" border="0"><tr>
      <td width="67px" align="center" valign='absmiddle'> &nbsp; <div class="S2">
          <a class="S3" target="_blank" rel="noopener noreferrer" title="The Ft Tele Story"
            href="https://devops1.sameboat.network/drucall"> About</a> </div>
        
        <div class="S8C" id="localClock2"></div>
        </td> 
        <td> &nbsp; &nbsp; </td>
      <td align="center" > 
          <button class="dbc" id="rudeDoorbell" onClick={() => {this.buttonPress()}}></button>
          <div class="S9C"> <a title="Zoom, Ft Jitsi depending on profile settings, defaults to Ft Jitsi"
            href="https://jitsi.sameboat.network">VT Provider</a></div>
        </td>
      <td width="55px" valign="center" align="left">
       <a href="/?TCOLL=0"><img alt=""  title="expand Ft legendbar" src="flap.png"/></a><br></br></td>
      <td ></td>  
      </tr>
   </table>)




    }
  }


function tick() {
  const dsd     = new DSDate();
  const prefix  = (shortTaskBar) ? "" : " UA: " + window.clientUA + " @ "; //COLL
  const element = (
    <div>
      <h2>{ prefix + new Date().toLocaleTimeString() + "  " }</h2>
    </div>
  );
  const element2 = (
    <div>
      <h2>{ dsd.twoDigitTOD() }</h2>
      <a title="About the domain space time standard ..." target="_blank"
         href="https://juan.ai-integration.biz/everything/index.pl?node_id=755"><span class="dsDate">{dsd.dsYearAndDay()}</span></a>
    </div>
  );
  if (document.getElementById("localClock"))
    ReactDOM.render(element, document.getElementById('localClock'));
  else
    ReactDOM.render(element2, document.getElementById('localClock2'));
}


setInterval(tick, 1000);

export default App;
